import styled from 'styled-components';

export const MainContent = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Image = styled.div`
	.img_div {
		margin-top: -70px;
	}

	img {
		width: 100%;
		height: 550px;
		object-fit: cover;
		position: relative;
		z-index: -1;
	}

	h1,
	p,
	button {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: white;
	}

	p {
		top: 60%;
		left: 50%;
		font-weight: bolder;
	}

	button {
		top: 70%;
		left: 50%;
		background-color: white;
		border: 1px dotted black;
		color: black;
		padding: 10px 20px;

		&:hover {
			border: 1px dotted white;
			color: white;
			background-color: black;
			transition: 0.5s;
		}
	}

	@media (max-width : 1000px){

		h1{
			top : 30%;	
		}
		p{
			top : 45%;
		}
		button {
			top : 60%;
		}
	}
`;

export const ContentSection = styled.div``;

export const Recomendation = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap : wrap;
	justify-content: space-between;
	align-items: center;
	padding: 40px 7px;

	.img_description {
		display: flex;
		flex-direction: column;
		padding: 30px 0px;
		font-family: Arial, Helvetica, sans-serif;

		p,
		span {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			color: black;
			font-size: 25px;
			width: 100%;
			margin-top: 5px;
		}

		span {
			font-weight: normal;
			color: grey;
			font-size: 15px;
			padding: 5px;
		}
	}

	img {
		width: 380px;
		height: 514px;
		object-fit: cover;
	}

	@media (max-width : 1000px){
		img {
			width: 340px;
			height: 326px;
			object-fit: cover;
		}
	}
`;

export const NewArrivals = styled.div``;

export const RadicalTransparency = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap : wrap;
	justify-content: space-between;
	align-items: center;
	padding: 50px 7px;

	img {
		width: 600px;
		height: 426px;
		object-fit: cover;
		margin-top: 10px;

		&:hover {
			cursor: pointer;
		}
	}

	.img_description {
		display: flex;
		flex-direction: column;
		flex-wrap : wrap;
		padding: 20px 0px;
		font-family: Arial, Helvetica, sans-serif;

		&:hover {
			cursor: pointer;
		}

		p,
		span {
			display: flex;
			flex-wrap: wrap;
			color: black;
			font-size: 25px;
			float: left;
			width: 100%;
		}

		span {
			font-weight: normal;
			color: grey;
			font-size: 15px;
			padding: 5px;
		}
	}

	button {
		top: 70%;
		left: 50%;
		background-color: white;
		border: 1px solid black;
		color: black;
		padding: 10px 20px;

		&:hover {
			border: 1px solid white;
			color: white;
			background-color: black;
			transition: 0.5s;
		}
	}

	@media (max-width : 1000px){
		img {
			width: 350px;
			height: 226px;
			object-fit: cover;
		}
	}
`;
