import styled from 'styled-components';

export const Container = styled.div`
	margin: 20px auto;
	padding: 30px;
	max-width: 1020px;
	min-height: 62vh;
	text-align: center;
`;

export const ContainerEmptyCart = styled.div`
	display: flex;
	margin: 20px auto;
	padding: 30px;
	max-width: 1020px;
	min-height: 62vh;
	text-align: center;
	justify-content: center;
`;

export const Headline = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin: 0;
	padding-bottom: 50px;

	p {
		font-size: 20px;
	}
`;

export const CheckoutButton = styled.button`
	padding: 15px 30px;
	background: #222;
	color: #fff;
	font-weight: bold;

	a{
		color : white;
		text-decoration: none;
	}
`;

export const Thead = styled.div`
	display: grid;
	grid-template-columns: 1fr 4fr 1fr 1fr 1fr 1fr;
	padding: 0px 0px 15px;
	margin-bottom: 25px;
	border-bottom: 1px solid #999;
`;

export const Trow = styled.div`
	display: grid;
	grid-template-columns: 1fr 4fr 1fr 1fr 1fr 1fr;
	padding: 10px 0px;
	align-items: center;

	img {
		width: 120px;
	}
`;

export const Tfoot = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin: 0;
	border-top: 1px solid #999;
	margin-top: 25px;
	padding-top: 15px;

	p {
		font-size: 20px;
	}

	span {
		display: flex;
		align-items: center;
		margin: 0;
		justify-content: flex-end;

		p {
			padding-right: 5px;
			font-weight: bold;
		}
	}
`;

export const EmptyCart = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: 100%;

	span {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		border: 1px solid #999;
		border-radius: 6px;
		padding: 20px;
	}

	p {
		font-size: 18px;
	}
`;
