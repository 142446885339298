import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { ShopContext} from "../../context/ShopContext";
import { EachProduct } from "./each_product_styles";

const Each_product = () => {

    let { id } = useParams();
    const { fetchProductWithId, addItemToCheckout, product } = useContext(ShopContext)
    const [SelectedOption, setSelectedOption] = useState();
    const [SelectedVariantId, setSelectedVariantId] = useState();
    const [SelectedVariantCost, setSelectedVariantCost] = useState();
    const [SelectedVariantColor, setSelectedVariantColor] = useState();

    useEffect(() => {
        fetchProductWithId(id)
        return () => {}
    },[fetchProductWithId, id])

    if(!product.title) return <div>Loading Product Details ....</div>

    return (
        <EachProduct>
            <div>
                <h1>{product.title}</h1><br />

                <img alt="No image to show" src={ !SelectedOption ?  (!product.variants[0].image ? (<h1>No images to show</h1>) : (product.variants[0].image.src)) : SelectedOption } /><br /><br />
                
                <p>{product.description}</p><br />
                <p>Price:{!SelectedVariantCost ? " "+product.variants[0].price : " "+SelectedVariantCost}</p><br />
                  {
                    product.options.length > 1 ?
                    <>

                        <select onChange={(e) => {
                          const NowSelectedOption = e.target.value.split(",")
                          setSelectedOption(NowSelectedOption[1]);
                          setSelectedVariantId(NowSelectedOption[0]);
                          setSelectedVariantCost(NowSelectedOption[2]);
                          setSelectedVariantColor(NowSelectedOption[3]);
                          console.log("Select option "+SelectedOption);
                        }}>
                          {product.variants.map(each_var => {
                            var myarr = each_var.title.split("/");
                            return(
                              <>
                                <option id="opt" value={[each_var.id,(!each_var.image ? (null) : (each_var.image.src)),each_var.price,each_var.selectedOptions[0].value]}>
                                  {myarr[0]}   {myarr[1]}                 
                                </option>
                              </>
                            )
                          })} 
                        </select>
                          {console.log("selected color "+ SelectedVariantColor)}
                          {/* {product.variants.map(each_var => {
                            return(
                              <>
                              {each_var.selectedOptions.map(each_option => {
                                if(!each_option.value) return(<p>Loading...</p>)
                                if(each_option.value == SelectedVariantColor){
                                  return(
                                    <p>{each_var.selectedOptions[1].value}</p>
                                  )
                                }
                              })}
                              </>
                            )
                          })} */}
                          
                      
                      
                          </>
                        :

                        <p>Only One variant</p>
                  }

                {/* <p>{SelectedOption }</p> */}      
                <br /><br />
                <button onClick={() => addItemToCheckout( !SelectedVariantId ? product.variants[0].id : SelectedVariantId, 1)}>Add To Cart</button>
            </div>
        </EachProduct>
    )

    // return (
    //     <EachProduct>
    //         <div>
    //             <h1>{product.title}</h1>
               
    //             {/* {
    //               product.options.length > 1 ?
    //               <>
    //               {product.variants.map(each_var => {
    //                   if(each_var.selectedOptions[0].value == SelectedOption){
    //                     return(
    //                       <>
    //                       <img src={ each_var.image.src } />
    //                       </>
    //                     )
    //                   }
    //               }) }


    //               </>
    //               :
    //               <img src={product.variants[0].image.src} />
    //             } */}
                
    //             {product.options.length > 1 ? 

    //             <>
                
    //             <select onChange={(e) => {
    //               const NowSelectedOption = e.target.value;
    //               setSelectedOption(NowSelectedOption);
    //             }}>

    //               {product.options.map(each_option => {
    //                 // <p></p>
    //                 if(each_option.name.includes("Color")){
    //                   {each_option.values.map(each_val => {
              
    //                     return(
    //                       each_val.value
    //                     )
    //                   })}
    //                 }
                    
    //               })}
    //             </select>

    //             {/* <select>

    //             {product.options.map(each_option => {
    //                 if(each_option.name == "Size"){
    //                   {product.variants.map(each_var => {
    //                     if(each_var.selectedOptions[0].value == SelectedOption){
    //                       return(
    //                         <>
    //                         <option value={each_var.selectedOptions[1].value} >{each_var.selectedOptions[1].value}</option>
    //                         </>
    //                       )
    //                     }
    //                 }) }
    //                 }
    //               })}
    //             </select> */}

    //             </>
    //             :

    //             <h1>Only one variant available</h1>

    //           }


    //             {/* <p>{SelectedOption }</p> */}
    //             <button>Add To Cart</button>
    //         </div>
    //     </EachProduct>
    // )
}

export default Each_product;
