import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const UpperStrip = styled.div`
	background-color: #000;
	color: white;
	min-height: 25px;
	display: flex;
	font-size: 12px;
	font-weight: bold;
	padding: 10px 5px;
	height: 100%;

	div {
		display: flex;
		flex-grow: 1;
		justify-content: space-around;
		align-items: center;
	}
`;

export const NavStrip = styled.div`
	display: flex;
	justify-content: space-evenly;
	min-height: 68px;
	align-items: center;
	position: relative;
	z-index: 1;
	margin-top: 0px;


	svg {
		fill: #fff;
	}

	&:hover {
		background-color: white;
		color: grey;
		cursor: pointer;
		transition: 0.3s;

		svg {
			fill: #999;
			transition: 0.3s;
		}

		a,
		a:visited {
			color: gray;
			transition: 0.3s;
		}

		#left-strip-container {
			button {
				color: grey;
				transition: 0.3s;
				height: 100%;
			}
		}
	}

	@media (max-width: 1000px) {
		width : 100%;
	}
`;

export const LeftStrip = styled.div`
	height: 68px;

	#left-strip-container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		min-width: 250px;
		height: 100%;
		width: 100%;

		button {
			background: none;
			color: #fff;
			height: 100%;
			flex-shrink: 1;
			padding: 0 15px;

			&:hover {
				border-bottom: 1px solid black;
				transition: 0s;
				color: black;
			}
		}

		#container-menu {
			float: left;
			overflow: hidden;
			height: 100%;

			#dropdown-menu {
				display: flex;
				visibility: hidden;
				position: absolute;
				padding: 20px 35px;
				background-color: #f1f1f1;
				width: 100%;
				height: 100%;
				top: 68px;
				left: 0;
				z-index: 1;
				opacity: 0;
				transition: opacity 0.1s linear 0s;

				flex-direction: column;
				flex-wrap: wrap;
				align-items: center;
			}

			&:hover {
				#dropdown-menu {
					visibility: visible;
					opacity: 1;
				}
				button{
					border-bottom: 1px solid black;
					transition: 0s;
					color: black;
				}
			}
		}
	}

	.collection_title{
		padding : 5px 0px;
		color : black;

		&:hover{
			color : grey;
		}
	}

	@media (max-width: 1000px) {
		#left-strip-container{
			justify-content : center;
			margin-left : 80px;
			button{
				font-size : 10px;
				padding : 0px 7px;
				
			}
		}
	  }
`;

export const RightStrip = styled.div`
	div {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		min-width: 250px;

		a {
			display: flex;
			align-items: center;
			text-decoration: none;
			color: white;
		}
	}

	&:hover{
		cursor : pointer;
	}

	@media (max-width: 1000px) {
		div{
			justify-content : flex-end;
			padding : 0px 7px;
			
			a{
				margin-right : 160px;
			}
		}
	}
`;

export const Title = styled(Link)`

	font-weight: bold;
	font-size: large;
	text-decoration: none;
	color: white;
	margin-left : -110px;

	@media (max-width: 1000px) {
		font-size : small;
		margin-left : 20px;
	}
`;

export const Menu = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: center;
`;

export const MenuImages = styled.div`
	display: flex;
	justify-content: center;

	div {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		align-items: center;
	}

	img {
		padding: 10px 10px 5px;
	}
`;

export const MenuLinks = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: row;
	width: 640px;

	> div {
		display: flex;
		flex-direction: column;
		text-align: center;
		> p:first-child {
			font-size: 16px;
			font-weight: bold;
		}
		> p {
			color: black;
			padding: 12px 16px;
			display: block;
			min-width: 160px;

			grid-area: item;

			&:hover {
				color: black;
			}
		}
	}
`;
