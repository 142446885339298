import React, {useContext, useEffect} from 'react'
import { ShopContext } from "../../context/ShopContext";
import { Link } from 'react-router-dom';
import { MainContent, CollectionHeading } from "./styles";

const Display_products = () => {

    const { fetchAllProducts, products } = useContext(ShopContext)
    // const { fetchAllCollections, collections } = useContext(ShopContext)
    const { addItemToCheckout } = useContext(
		ShopContext
	);


    useEffect(() => {
        fetchAllProducts()
        // fetchAllCollections()
        return () => {

        };
    }, [fetchAllProducts])

    if(!products) return <div>Loading... Products</div>
    // if(!collections) return <div>Loading...collections</div>

    return(
        <>
        <CollectionHeading>
        <h1>Trendy Collections</h1>
        </CollectionHeading>
        <MainContent>
            {products.map(product => (
            <div className="each_product">
                <div className="card" key={product.id}>
                    <div className="card_img">
                    <Link to={`/Each_product/${product.id}`}>
                            <img src={product.variants[0].image.src} />
                    </Link>
                            <button onClick={() => addItemToCheckout( product.variants[0].id, 1)} className="btn">Add to cart</button>
                    </div>
                    <div className="card_header">
                        <h2>{product.title}</h2>
                        {/* <p>{product.description}</p> */}
                        <p className="price">{product.variants[0].price}<span>{product.variants[0].priceV2.currencyCode}</span></p>
                        <Link to={`/Each_product/${product.id}`}>
                        { product.variants.length <= 1 ? <button className="btn_variant">Only one Variant Available</button>
                         : <button className="btn_variant">Choose from {product.variants.length} variants!</button> }       
                         </Link> 
                    </div>
                </div>
            </div>
            )) }
        </MainContent>
        </>
    )
}

export default Display_products