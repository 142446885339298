import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ShopContext } from "../../../context/ShopContext";
import { MainContent, CollectionHeading } from "../styles";

function Denim_coll() {
	// const { fetchAllProducts, products } = useContext(ShopContext)
	// const { fetchAllCollections, collections } = useContext(ShopContext)
	const { fetchCollectionWithHandle, single_collection } = useContext(
		ShopContext
	);
	const { addItemToCheckout } = useContext(
		ShopContext
	);

	useEffect(() => {
		// fetchAllProducts()
		// fetchAllCollections()
		fetchCollectionWithHandle('denim');
		return () => {};
	}, [fetchCollectionWithHandle]);

	// if(!products) return <div>Loading... Products</div>
	// if(!collections) return <div>Loading...collections</div>
	if (!single_collection.title) return <div>Loading...</div>;

	return (
		<>
			{single_collection.products.length <= 0 ? (
				<div className='card'>
					<CollectionHeading>
						<div className='collection_heading'>
							<h1>{single_collection.title}</h1>
							<br />
							<h3> No Products to Show </h3>
						</div>
					</CollectionHeading>
				</div>
			) : (
				<>
					<CollectionHeading>
						<div className='collection_heading'>
							<h1>{single_collection.title}</h1>
						</div>
					</CollectionHeading>
					<MainContent>
						{single_collection.products.map((each_product) => (
							<div className='each_product'>
								<div className='card' key={each_product.id}>
									<div className='card_img'>
										<Link to={`/Each_product/${each_product.id}`}>
										{!each_product.variants[0].image ? (<img alt="No Image to show"/>) : (<img src={each_product.variants[0].image.src} />)}
										</Link>
										<button onClick={() => addItemToCheckout( each_product.variants[0].id, 1)} className='btn'>
											Add to cart	
										</button>
									</div>
									<div className='card_header'>
										<h2>{each_product.title}</h2>
										<p className='price'>
											{each_product.variants[0].price}<span>{" "+each_product.variants[0].priceV2.currencyCode}</span>
										</p>
										<Link to={`/Each_product/${each_product.id}`}>
											{each_product.variants.length <= 1 ? (
												<button className='btn_variant'>
													Only one Variant Available
												</button>
											) : (
												<button className='btn_variant'>
													Choose from {each_product.variants.length} variants!
												</button>
											)}
										</Link>
									</div>
								</div>
							</div>
						))}
					</MainContent>
				</>
			)}
		</>
	);
}

export default Denim_coll;
