import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { ShopContext } from '../../context/ShopContext';
import { MainContent, CollectionHeading } from './styles';

const Collections = () => {

    let { id } = useParams();
    const { fetchCollectionWithProducts, testing_collection } = useContext(ShopContext);
    const { addItemToCheckout } = useContext(
		ShopContext
	);

    useEffect(() => {
        fetchCollectionWithProducts(id);
        return() => {}
    },[fetchCollectionWithProducts, id]);

    if(!testing_collection.title) return (<p>Loading collection details....</p>)

    return (
		<>
			{testing_collection.products.length <= 0 ? (
				<div className='card'>
					<CollectionHeading>
						<div className='collection_heading'>
							<h1>{testing_collection.title}</h1>
							<br />
							<h3> No Products to Show </h3>
							
						</div>
					</CollectionHeading>
				</div>
			) : (
				<>
					<CollectionHeading>
						<div className='collection_heading'>
							<h1>{testing_collection.title}</h1>
						</div>
					</CollectionHeading>
					<MainContent>
						{testing_collection.products.map((each_product) => (
							<div className='each_product'>
								<div className='card' key={each_product.id}>
									<div className='card_img'>
										<Link to={`/Each_product/${each_product.id}`}>
										{!each_product.variants[0].image ? (<img alt="No image to show"/>) : (<img src={each_product.variants[0].image.src} />)}
										</Link>
										<button onClick={() => addItemToCheckout( each_product.variants[0].id, 1)} className='btn'>
											Add to cart
										</button>
									</div>
									<div className='card_header'>
										<h2>{each_product.title}</h2>
										<p className='price'>
											{each_product.variants[0].price}<span>{" "+each_product.variants[0].priceV2.currencyCode}</span>
										</p>
										<Link to={`/Each_product/${each_product.id}`}>
											{each_product.variants.length <= 1 ? (
												<button className='btn_variant'>
													Only one Variant Available
												</button>
											) : (
												<button className='btn_variant'>
													Choose from {each_product.variants.length} variants!
												</button>
											)}
										</Link>
									</div>
								</div>
							</div>
						))}
					</MainContent>
				</>
			)}
		</>
	);
}

export default Collections
