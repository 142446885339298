import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import ShopProvider from './context/ShopContext';

import Main from './pages/Main';
import Cart from "./pages/Cart/Cart";
import Display_Navbar_collections from './pages/Products/Display_Navbar_collections';
import Display_products from './pages/Products/Display_products';
import Each_product from './pages/Products/Each_product';
import Collections from './pages/Products/Collections';
import Women_coll from "./pages/Products/Collections/Women_coll";
import Men_coll from "./pages/Products/Collections/Men_coll"
import Denim_coll from "./pages/Products/Collections/Denim_coll";
import Sale_coll from "./pages/Products/Collections/Sale_coll";

export default function Routes() {
	return (
		<ShopProvider>
	
			<Switch>
				<Route path='/' exact component={withRouter(Main)} />
				<Route path='/women' exact component={withRouter(Women_coll)} />
				<Route path='/men' exact component={withRouter(Men_coll)} />
				<Route path='/denim' exact component={withRouter(Denim_coll)} />
				<Route path='/sale' exact component={withRouter(Sale_coll)} />
				<Route path="/Each_product/:id" exact component={withRouter(Each_product)} />
				<Route path="/all-products" exact component={withRouter(Display_products)} />
				<Route path="/cart" exact component={withRouter(Cart)} />
				<Route path="/collection/:id" exact component={withRouter(Collections)} />
			</Switch>
		
		</ShopProvider>
	);
}
