import styled from 'styled-components';

export const EachProduct = styled.div`
display : flex;
flex-direction : column;
color : black;

img {
    height : 300px;
    width : 300px;
    padding : 5px 10px;
    border : 2px solid black;
    object-fit: cover;

    &:hover{
        cursor : pointer;
    }
}

button {
    padding : 5px 10px;
    background-color : black;
    color : white;
    border : 1px solid black;

    &:hover {
        background-color : white;
        color : black;
    }
}
`