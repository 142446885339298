import styled from 'styled-components';

export const OuterContainer = styled.div`
	display: flex;

	padding-top: 30px;
	border-top: 1px solid #ddd;
	background-color: #eee;
	width: 100%;
	
`;

export const InnerContainer = styled.div`
	color: #333;
	display: flex;
	width: 1024px;
	padding-bottom: 8px;
	flex-wrap: wrap;

	margin: auto;
	text-transform: uppercase;

	div {
		display: flex;
		flex-direction: column;
		align-items: left;
		flex-grow: 1;
		padding: 15px 10px;

		p:first-child {
			margin-bottom: 20px;
			font-size: 13px;
			font-weight: bold;
		}

		p {
			font-size: 11px;
			margin-bottom: 10px;
		}
	}
`;

export const Subscribe = styled.form`
	display: flex;
	flex-direction: column;
	flex-grow: 2;
	padding: 0 10px;

	p {
		margin: 5px 0 15px;
	}

	input {
		line-height: 30px;
		border: 1px solid #999;
		border-radius: 4px;
		margin-bottom: 10px;
		padding: 0 13px;

		::placeholder {
			color: #999;
			margin: 10px;
		}
	}

	button {
		width: 150px;
		padding: 10px;
		color: #eee;
		background-color: #333;
		border-radius: 3px;
	}
`;
