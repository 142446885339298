import React from 'react';

import { OuterContainer, InnerContainer, Subscribe } from './styles';

function Footer() {
	return (
		<OuterContainer>
			<InnerContainer>
				<Subscribe>
					<p>sign up for latest news</p>
					<input type='text' placeholder='Enter Your Email Adress' />
					<button type='submit'>SUBSCRIBE</button>
				</Subscribe>
				<div>
					<p>Here to help</p>
					<p>Help Center</p>
					<p>Contact us</p>
					<p>Terms and Conditions</p>
					<p>Size Chart</p>
					<p>My Account</p>
				</div>
				<div>
					<p>about us</p>
					<p>the brand</p>
					<p>underwear club</p>
				</div>
				<div>
					<p>shopping with us</p>
					<p>delivery</p>
					<p>returns</p>
					<p>track your order</p>
					<p>Special offers</p>
				</div>
				<div>
					<p>follow us</p>
					<p>F</p>
					<p>T</p>
					<p>I</p>
				</div>
			</InnerContainer>
		</OuterContainer>
	);
}

export default Footer;
