import styled from 'styled-components';

export const MainContent = styled.div`

display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content : space-evenly;
background-color: #eee;

    .each_product{
        padding : 25px 10px;
    }

    .card{
        display : flex;
        flex-direction : column;
        justify-content : center;
        width : 338px;
        height : 600px;
        position : relative;
        z-index : 0;

        &:hover{
            cursor : pointer;
            box-shadow : 2px black;
        }

        &:hover .btn{
            transition : 0.5s;
            display : inline-block;
        }
    }

    .card_img img{
        width : 338px;
        height : 422px;
        object-fit: cover;
    }

    .card_header{
        width : 338px;
        height : 80px;
        padding : 5px 0px;

        h2{
            display : flex;
            font-size: medium;
            padding : 5px 0px;
        }
    }

    .btn{
        padding : 10px 5px;
        position: absolute;
        top: 70%;
        left: 50%;
        transform: translate(-50%, -50%);
        width : 80%;
        display : none;
        color : black;
        backgroung-color: white;
    }

    .btn:hover{
        transition : 0.5s;
        border : 1px solid white;
        color : white;
        background-color : black;
    }

    .btn_variant{
        padding : 10px 5px;
        width : 100%;
        color : black;
        background-color: white;

        &:hover{
            transition : 0.5s;
            border : 1px solid white;
            color : white;
            background-color : black;
        }
    }

    .price{
        display : flex;
    }

    span{
        color : grey;
        font-size : small;
        margin-top : 1px;
    }
`;

export const CollectionHeading = styled.div`

display:flex;
justify-content : center;


`