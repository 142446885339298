import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Routes from './routes';
import GlobalStyle from './styles/global';
import store from './store';

function App() {
	return (
		<Provider store={store}>
			<BrowserRouter>
				<GlobalStyle />
				<Header />
				<Routes />
				<Footer />
			</BrowserRouter>
		</Provider>
	);
}

export default App;
