import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import ShopContext from './context/ShopContext';

ReactDOM.render(
  <React.StrictMode>
    <ShopContext>
      <App />
    </ShopContext>
  </React.StrictMode>,
  document.getElementById('root')
);
