import React, { Component } from 'react';
import Client from "shopify-buy";


const ShopContext = React.createContext();
const API = process.env.REACT_APP_API_KEY;

const client = Client.buildClient({
    storefrontAccessToken: API,
    domain : 'bearboxers-menswear.myshopify.com'
});

class ShopProvider extends Component {

    state = {
        products : [],
        product : {},
        checkout : {},
        collections : [],
        single_collection : {},
        group_collection : [],
        testing_collection : {},
        isCartOpen : false,
        test : 'test'
    }

    componentDidMount() {
        this.createCheckout();
        this.fetchAllProducts();
        this.fetchAllCollections();
    }

        createCheckout = async () => {
            const checkout = await client.checkout.create();
            this.setState({ checkout : checkout });
        }

        // createCustomer = async () => {
        //     cosnt customer = await client.customer.create();
        // }

        addItemToCheckout = async (variantId, quantity) => {

            const lineItemsToAdd = [{
            variantId,
            quantity : parseInt(quantity, 10)
        }]

        const checkout = await client.checkout.addLineItems(this.state.checkout.id, lineItemsToAdd)
        this.setState({ checkout : checkout });
        alert ("Item added to cart");
    }

    fetchAllProducts = async () => {
        const products = await client.product.fetchAll();
        // console.log("Products " + products);
        this.setState({ products : products});
    }

    test = async () => {
        const customer = await client.customer.customerCreate()

        this.setState({customer })
    }

    fetchProductWithId = async (id) =>{
        const product = await client.product.fetch(id);
        this.setState({ product : product});    
    }

    fetchAllCollections = async () =>{
        const collections = await client.collection.fetchAllWithProducts();
        // console.log("collections "+collections);
        // console.log("collections[0] "+collections[0].products);  
        this.setState({ collections : collections });
       }

       fetchCollectionWithHandle = async (name) => {
           const single_collection = await client.collection.fetchByHandle(name)
            this.setState({ single_collection : single_collection });
       }

       fetchCollectionWithProducts = async (id) => {
           const testing_collection = await client.collection.fetchWithProducts(id)
            this.setState({ testing_collection : testing_collection });
       }

       

    closeCart = () => { this.setState({ isCartOpen : false }) }

    openCart = () => { this.setState({ isCartOpen : true }) }

    render() {
        return (
           <ShopContext.Provider value={{
                ...this.state,
                fetchAllProducts : this.fetchAllProducts,
                fetchProductWithId : this.fetchProductWithId,
                fetchAllCollections : this.fetchAllCollections,
                closeCart : this.closeCart,
                openCart : this.openCart,
                addItemToCheckout : this.addItemToCheckout,
                fetchCollectionWithHandle : this.fetchCollectionWithHandle,
                fetchCollectionWithProducts : this.fetchCollectionWithProducts
            }} >
               {this.props.children}
           </ShopContext.Provider>
           
        )
    }
}

const ShopConsumer = ShopContext.Consumer

export {ShopConsumer, ShopContext}

export default ShopProvider
