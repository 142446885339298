import produce from 'immer';

export default function cart(state = [], action) {
	console.log(action);

	switch (action.type) {
		case 'ADD_TO_CART':
			return produce(state, (draft) => {
				const productIndex = draft.findIndex((p) => p.id === action.product.id);

				if (productIndex >= 0) {
					draft[productIndex].quantity += 1;
				} else {
					draft.push({
						...action.product,
						quantity: 1,
					});
				}
			});

		default:
			return state;
	}
}
