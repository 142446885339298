import React from 'react';
import mainImg from './images/main_img1.jpg';
import dressCode from './images/dress_code.jpg';
import short from './images/short.jpg';
import trackPant from './images/track_pant.jpg';
import sewingMachine from './images/sewing_machine.jpg';
import organicCotton from './images/organic_cotton.jpg';
import Slide_show from "./Slide-show/Slide_show";
import { Link } from 'react-router-dom';

import {
  Image,
  ContentSection,
  Recomendation,
  MainContent,
  NewArrivals,
  RadicalTransparency,
} from './styles';

function Main() {
  return (
    <>
      <MainContent>
        <Image>
          <div className="img_div">
            <img src={mainImg} alt="Main Banner" />
          <div className="img_content">
            <h1>DISCOVER THE NEW COLLECTION</h1>
            <p>Brave the moment in pieces designed for leisure, movement and comfort in distinctive Bearboxers style.</p>
            <Link to="/all-products">
            <button>SHOP NOW</button>
            </Link>
            </div>
          </div>
        </Image>

        <ContentSection>
          <Recomendation>
            <div className="each_img">
              <Link to="/men">
              <img src={short} alt="Short" />
              </Link>
              <div className="img_description">
                <p>The Easy short</p>
                <span>
                  Our best-selling Easy Short, now in unbelievably soft denim.
                </span>
              </div>
            </div>

            <div className="each_img">
              <Link to="/men" >
              <img src={dressCode} alt="Dress" />
              </Link>
              <div className="img_description">
                <p>The New Dress Code</p>
                <span>Fresh pairings to spruce up your wardrobe.</span>
              </div>
            </div>

            <div className="each_img">
              <Link to="/women">
              <img src={trackPant} alt="Track Pant" />
              </Link>
              <div className="img_description">
                <p>Introducing Track</p>
                <span>
                  A collection of premium organic pieces —made for the track
                  ahead
                </span>
              </div>
            </div>
          </Recomendation>

          <NewArrivals>
            <Slide_show />
          </NewArrivals>

          <RadicalTransparency>
            <div className="each_img">
            <a target="_blank" href="https://www.sustainablejungle.com/sustainable-fashion/sustainable-fabrics/">
              <img src={organicCotton} alt="Organic Cotton" />
              </a>
              <div className="img_description">
                <p>Recycled Materials, Organic Cotton, and More.</p>
                <span>
                <a type="button" target="_blank" href="https://www.sustainablejungle.com/sustainable-fashion/sustainable-fabrics/">
                <button>Learn More</button>
                </a>
                </span>
              </div>
            </div>

            <div className="each_img">
            <a type="button" target="_blank" href="https://passionlilie.com/blogs/designersjournal/ethical-sustainable-clothing-important">
              <img src={sewingMachine} alt="Sewing Machine" />
              </a>
              <div className="img_description">
                <p>Ethically Made. Designed to Last.</p>
                <span>
                <a target="_blank" href="https://passionlilie.com/blogs/designersjournal/ethical-sustainable-clothing-important">
                <button>Learn More</button>
                </a>
                </span>
              </div>
            </div>
          </RadicalTransparency>
        </ContentSection>
      </MainContent>
    </>
  );
}

export default Main;
