import React, { useContext, useEffect } from 'react';
import { FiShoppingCart } from 'react-icons/fi';
import {
	LeftStrip,
	RightStrip,
	NavStrip,
	UpperStrip,
	Title,
	Menu,
	MenuImages,
	MenuLinks,
} from './styles';

import { Link } from 'react-router-dom';
import { ShopContext } from "../../context/ShopContext";

const Header = () => {

	const { fetchAllCollections, collections } = useContext(ShopContext);

	useEffect(() => {

		fetchAllCollections();

		return () => {}
	}, [fetchAllCollections])

	// if(!collections.title) return (<div>Loading...</div>)

	// const dropdownMenuContent = {
	// 	firstMenuContent: {
	// 		Menu: {
	// 			Clothing: ['test1', 'test1', 'test1', 'test1'],
	// 			Shoes: ['test1', 'test1', 'test1', 'test1'],
	// 			Acessories: ['test1', 'test1'],
	// 			Collection: ['test1'],
	// 		},
	// 		Images: [
	// 			['test image', testImage, 'SHOP NEW ARRIVALS'],
	// 			['test image 2', testImage, 'SHOP SHIRTS'],
	// 		],
	// 	},
	// 	secondMenuContent: {
	// 		Menu: {
	// 			Clothing: ['test2', 'test2', 'test2', 'test2'],
	// 			Shoes: [],
	// 			Acessories: [],
	// 			Collection: [],
	// 		},
	// 		Images: [
	// 			['test image', testImage, 'SHOP NEW ARRIVALS'],
	// 			['test image 2', testImage, 'SHOP SHIRTS'],
	// 		],
	// 	},
	// 	thirdMenuContent: {
	// 		Menu: {
	// 			Menswear: ['test3', 'test3', 'test3', 'test3'],
	// 			Womenswear: ['test3', 'test3', 'test3', 'test3'],
	// 		},
	// 		Images: [
	// 			['test image', testImage, 'SHOP NEW ARRIVALS'],
	// 			['test image 2', testImage, 'SHOP SHIRTS'],	
	// 		],
	// 	},
	// 	forthMenuContent: {
	// 		Menu: {
	// 			Clothing: ['test4', 'test4', 'test4', 'test4'],
	// 			Shoes: [],
	// 			Acessories: [],
	// 			Collection: [],
	// 		},
	// 		Images: [],
	// 	},
	// };

	// const createMenuFromMenuContent = (menuContent) => (
	// 	<Menu>
	// 		<MenuLinks>
	// 			{Object.entries(menuContent.Menu).map(([title, items], key) => (
	// 				<div>
	// 					<p key={key}>{title}</p>
	// 					{items.map((subItem, subkey) => (
	// 						<p key={subkey}>{subItem}</p>
	// 					))}
	// 				</div>
	// 			))}
	// 		</MenuLinks>
	// 		<MenuImages>
	// 			{menuContent.Images.map(([altText, url, text]) => (
	// 				<div>
	// 					<img src={url} alt={altText} width='250' height='350' />
	// 					<p>{text}</p>
	// 				</div>
	// 			))}
	// 		</MenuImages>
	// 	</Menu>
	// );

	// const page_reload = () => {
	// 	window.location.reload();
	// 	// alert("page_reloaded");
	// }

	return (
		<>
			<UpperStrip>
				<div>
					<marquee direction="right" scrollamount="10">10% off on your first order * </marquee>
				</div>
			</UpperStrip>
			<NavStrip>
				<LeftStrip>
					<div id='left-strip-container'>
						<div id='container-menu'>
						<Link to={'/women'} >
							<button id='first-menu'>WOMEN</button>
							</Link>
							<div id='dropdown-menu'>
								{collections.map(collection => {
									if(!collection.title) return (<p>Loading...</p>)
									if(collection.title.toLowerCase().includes("men")) return (<></>);
									
										return(
											<Link to={`/collection/${collection.id}`} style={{ textDecoration: 'none' }}>
											<p className="collection_title">{collection.title}</p>
											</Link>
										)
								})}
							</div>
						</div>

						<div id='container-menu'>
						<Link to={'/men'} >
							<button id='second-menu'>MEN</button>
							</Link>
							<div id='dropdown-menu'>
							{collections.map(collection => {
									if(collection.title.toLowerCase().includes("men")) return (<></>);
									if(!collection.title) return (<p>Loading...</p>)
										return(
											<Link to={`/collection/${collection.id}`} style={{ textDecoration: 'none' }}>
											<p className="collection_title">{collection.title}</p>
											</Link>
										)
								})}
							</div>
						</div>

						<div id='container-menu'>
						<Link to={'/denim'} >
							<button id='third-menu'>Denim</button>
							</Link>
							<div id='dropdown-menu'>
							{collections.map(collection => {
								if(!collection.title) return (<p>Loading...</p>)
									return(
										<Link to={`/collection/${collection.id}`} style={{ textDecoration: 'none' }}>
										<p className="collection_title">{collection.title}</p>
										</Link>
									)
								})}
							</div>
						</div>
						<div id='container-menu'>
						<Link to={'/sale'} >
							<button id='forth-menu'>SALE</button>
							</Link>
							<div id='dropdown-menu'>
							{collections.map(collection => {
								if(!collection.title) return (<p>Loading...</p>)
									return(
										<Link to={`/collection/${collection.id}`} style={{ textDecoration: 'none' }}>
										<p className="collection_title">{collection.title}</p>
										</Link>
									)
								})}
							</div>
						</div>

						{/* <div id='container-menu'>
						<Link to={'/all-products'} >
							<button id='forth-menu'>All products</button>
							</Link>
							<div id='dropdown-menu'>
								{collections.map(collection => {
										return(
											<p>{collection.title}</p>
										)
								})}
							</div>
						</div> */}

					</div>

				</LeftStrip>
				<Title to='/' id="title"><div>BEARBOXERS</div></Title>
				<RightStrip>
					<div id="right-strip-container">
						{/* <p>Search</p>
						<p>Login</p>
						<p>Signup</p> */}
						{/* <Link to='/cart'>
							<ShoppingBasket /> [{cartSize}]
						</Link> */}
						<Link to="/cart">
						<p>Cart</p> <FiShoppingCart />
						</Link>
					</div>
				</RightStrip>
			</NavStrip>
		</>
	);
}

export default Header
