import React, { useContext, useEffect } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import test_img from "../images/short.jpg";
import "./Slide_show.css";
import { Link } from 'react-router-dom';
import { ShopContext } from "../../../context/ShopContext";


const Slide_show = () => {
  
  const { fetchCollectionWithHandle, single_collection } = useContext(
		ShopContext
	);

  useEffect(() => {
		// fetchAllProducts()
		// fetchAllCollections()
		fetchCollectionWithHandle('new-arrival');
		// fetchCollectionWithHandle('new arrival');
		return () => {};
	}, [fetchCollectionWithHandle]);

    let settings = {
        
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
        swipeToSlide: true,
        infinite: true,
        autoPlay: true,
        
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              adaptiveHeight: true,
              swipeToSlide: true,
              infinite: true,
              autoPlay: true,
            }
          }
        ]
    }

    if(!single_collection) return <div>Nothing to Show</div>
    if (!single_collection.title) return <div>Loading...</div>;

    return (
        <>
        <div className="main_slider">
        <div><h1>New Arrivals</h1></div>
        <Slider {...settings} className="slider">

          { single_collection.products.map(each_product => {
            return(

            <div className="each_img"><br/>
              <Link to={`/Each_product/${each_product.id}`}>
              {!each_product.variants[0].image ? (<h1>No Image to show</h1>) : (<img src={each_product.variants[0].image.src} />)}
              </Link>
              <div className="img_description">
                <p>{each_product.title}</p>
                <span>{each_product.variants[0].price} £</span>
              </div>
            </div>
            )

          })}

        </Slider>
        </div>
        </>
      );
}

export default Slide_show


// <div className="each_img"><br/>
//               <img src={test_img} alt="Short" />
//               <div className="img_description">
//                 <p>The Easy short</p>
//                 <span>£10</span>
//               </div>
//             </div>