// import React, { useContext } from 'react'
// import ShopContext from "../../context/ShopContext";

// import { ReactComponent as EmptyCartSVG } from './images/empty_shopping_cart.svg';

// import {
// 	Container,
// 	ContainerEmptyCart,
// 	Headline,
// 	Thead,
// 	Trow,
// 	Tfoot,
// 	CheckoutButton,
// 	EmptyCart,
// } from './styles';

// const Cart = () => {

//     const { checkout } = useContext(ShopContext);

// 	return (
// 		<Container className='container'>
// 			<Headline>
// 				<p>YOUR SHOPPING BAG</p>
// 				<CheckoutButton> Proceed to checkout </CheckoutButton>
// 			</Headline>

// 			<Thead>
// 				<p>Product</p>
// 				<p>Description</p>
// 				<p>Variant</p>
// 				<p>Price</p>
// 				<p>Quantity</p>
// 				<p>Amount</p>
// 			</Thead>

// 			{checkout.lineItems.map((product) => (
// 				<Trow key={product.id}>
// 					<p>
// 						<img src={product.variant.image.src} />
// 						<strong>{product.title}</strong>
// 					</p>
// 					{/* <p>{product.description}</p> */}
// 					<p>{product.size}</p>
// 					<p>{product.variant.price}</p>
// 					<p>{product.quantity}</p>
// 					<p>{product.variant.price}</p>
// 				</Trow>
// 			))}

// 			<Tfoot>
// 				<CheckoutButton>Proceed to checkout</CheckoutButton>
// 				<span>
// 					<p>Subtotal:</p>
// 					<p>{checkout.subtotalPrice}</p>
// 				</span>
// 			</Tfoot>
// 		</Container>
// 	) 
//     // : (
// 	// 	<ContainerEmptyCart className='container'>
// 	// 		<EmptyCart>
// 	// 			<span>
// 	// 				<strong>Your Basket is empty.</strong>
// 	// 				<p>Continue shopping on bearboxers.co.uk homepage.</p>
// 	// 				<EmptyCartSVG />
// 	// 			</span>
// 	// 		</EmptyCart>
// 	// 	</ContainerEmptyCart>
// 	// );
// }

// export default (Cart);

import React, { useContext } from 'react'
import { ShopContext } from '../../context/ShopContext';
import { ReactComponent as EmptyCartSVG } from './images/empty_shopping_cart.svg';

import {
	Container,
	ContainerEmptyCart,
	Headline,
	Thead,
	Trow,
	Tfoot,
	CheckoutButton,
	EmptyCart,
} from './styles';


const Cart = () => {

    const { checkout } = useContext(ShopContext);

    if(!checkout.lineItems) return <h1>loading ...</h1>

    return (
        <>
    {
        checkout.lineItems.length > 0 ?
        
        <Container className='container'>
            <Headline>
 				<p>YOUR SHOPPING BAG</p>
                 <a href={checkout.webUrl} target="_blank">
 				<CheckoutButton>
                  Proceed to checkout
                </CheckoutButton>
                </a>
 			</Headline>

 			<Thead>
 				<p>Product</p>
 				<p>Variant</p>
 				<p>Price</p>
 				<p>Quantity</p>
 				<p>Amount</p>
 			</Thead>
            
            {checkout.lineItems && checkout.lineItems.map(product => {
                return(
                    <Trow key={product.id}>
                        <p>
                            <img src={product.variant.image.src} />
                            <strong>{product.title}</strong>
                        </p>
                        {/* <p>{product.description}</p> */}
                        <p>{product.variant.title}</p>
                        <p>{product.variant.price}</p>
                        <p>{product.quantity}</p>
                        <p>{product.variant.price}</p>
                    </Trow>
                )
            })}

            <Tfoot>
                <a href={checkout.webUrl} target="_blank">
                    <CheckoutButton>
                        Proceed to checkout
                    </CheckoutButton>
                 </a>
 				<span>
 					<p>Subtotal:</p>
 					<p>{checkout.subtotalPrice}</p>
				</span>
			</Tfoot>
            </Container>

            :
            <ContainerEmptyCart className='container'>
                <EmptyCart>
                    <span>
                        <strong>Your Basket is empty.</strong>
                        <p>Continue shopping on bearboxers.co.uk homepage.</p>
                        <EmptyCartSVG />
                    </span>
                </EmptyCart>
 	        </ContainerEmptyCart>
    }

        </>
    )
}

export default Cart